<template>
  <v-dialog v-model="parent.uploadWeightShowModal" max-width="600px" persistent>
    <v-card>
      <v-card-title class="text-h6 white--text px-2 py-1" :class="titleClass">
        {{ labels.BTN_WEIGHT_LIST_TORIKOMI }}
      </v-card-title>
      <v-file-input
        class="px-4 pt-4 py-0"
        v-model="weightInputCsvFile"
        :label="labels.BTN_WEIGHT_LIST_TORIKOMI"
        :rules="weightInputFileRules"
        accept="text/csv"
        outlined
        clearable
        counter
        show-size
      >
      </v-file-input>
      <v-card-actions class="d-flex justify-end">
        <v-btn dense color="primary darken-1" :loading="uploadLoading" :disabled="!weightInputCsvFile" @click="upload">
          OK
        </v-btn>
        <v-btn
          dense
          color="orange darken-1"
          text
          :loading="uploadLoading"
          :disabled="uploadLoading"
          @click="parent.uploadWeightShowModal = false"
        >
          cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  props: {
    parent: {
      type: Object,
      default: Object,
      required: true,
    },
  },
  emits: ['uploaded'],
  data() {
    return {
      weightInputCsvFile: null,
      weightInputFileRules: [(value) => !value || value.size < 10000000 || 'CSVファイルサイズが10MBを超えてます！'],
    };
  },
  methods: {
    ...mapActions({
      parseCSV: 'common/parseCsv',
    }),
    async upload() {
      const parsed = await this.parseCSV(this.weightInputCsvFile);
      const res = [];
      parsed.forEach((row) => {
        res.push({
          carton_no: +row[0],
          /**
           * ボックス番号
           */
          box_no: row[1],
          /**
           * その他フラグ
           */
          other_part_item: +row[2],
          /**
           * カートン箱タイプ
           */
          carton_box_type: +row[3],
          /**
           * カートン総重量
           */
          gross_weight: +row[4],
        });
      });
      /**
       * 代表するrowのindex
       *  */
      let i = 0;
      const otherPartsItemIdx = [];
      const condi = [0, '', null, undefined];
      res.map(({ carton_no, other_part_item, carton_box_type, gross_weight }, idx) => {
        if (condi.includes(carton_no) || condi.includes(carton_box_type) || condi.includes(gross_weight)) {
          res[idx].carton_no = res[i].carton_no;
          res[idx].carton_box_type = res[i].carton_box_type;
          res[idx].gross_weight = res[i].gross_weight;
        } else {
          i = idx;
        }
        if (other_part_item === 1) {
          otherPartsItemIdx.push(idx);
        }
      });
      // csvにその他フラグが1になってたらそのカートン全てのボックにその他フラグを入れる
      otherPartsItemIdx.map((i) => {
        let j = i;
        while (res.length <= j && res[j].carton_no === res[j + 1].carton_no) {
          res[j + 1].other_part_item = 1;
          j++;
        }
      });
      // console.log(res);

      this.$emit('uploaded', res);
      this.weightInputCsvFile = null;
      this.parent.uploadWeightShowModal = false;
    },
  },
  computed: {
    ...mapGetters({
      labels: 'defined/labels',
      error: 'ui/error',
      success: 'ui/success',
      uploadLoading: 'ui/uploadLoading',
    }),
    titleClass() {
      return {
        red: this.error,
        primary: !this.error && !this.success,
        success: this.success,
      };
    },
  },
  watch: {
    'parent.uploadWeightShowModal'() {
      if (!this.parent.clickedWeightInput) {
        this.invoiceCsvFile = null;
        this.detailCsvFile = null;
      }
    },
  },
};
</script>
